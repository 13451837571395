import { DOCUMENT, Location } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { logger } from '@app/core/helpers/logger';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { NotFoundError } from '@app/lingo2-errors';
import { ServiceCardDisplayModeType } from '@core/components/service-card';
import { LoadingBarService } from '@core/components/spinners/loading-bar/loading-bar.service';
import {
  AccountService,
  AnalyticsService,
  AuthService,
  BillingV2Service,
  ClassroomsService,
  CommentService,
  ContextService,
  defaultClassroomDetails,
  FeaturesService,
  FilesService,
  fullUserServiceDetails,
  MeetingsService,
  MetaService,
  RequestService,
  UserServicesService,
} from '@core/services';
import { WebsocketService } from '@core/websocket';
import { environment } from '@env/environment';
import { ChangableComponent } from '@models/changable.component';
import { LibraryRouter } from '@models/library.router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { openMeetingRescheduleWindow } from '@store/actions/profile.actions';
import { addSeconds, differenceInMinutes } from 'date-fns';
import {
  Classroom,
  ConfirmationOptions,
  EContentPanelRoute,
  EMeetingUserAction,
  EMeetingUserState,
  EUserServiceType,
  FindAccountStrategyEnum,
  ImageSizeEnum,
  IPagedResults,
  IPagination,
  Meeting,
  MeetingAcceptStatusEnum,
  MeetingCategoryEnum,
  MeetingParticipant,
  MeetingTypeEnum,
  otherSubjectId,
  ReviewListItem,
  Router as ContentRouter,
  SubjectCategoryEnum,
  User,
  UserRoleEnum,
  UserService,
} from 'lingo2-models';
import { IAccountStats } from 'lingo2-models/dist/account/interfaces';
import { uniq } from 'lodash-es';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonState } from 'onclass-ui';
import { OnUiCover } from 'onclass-ui/lib/modules/on-ui-cover/on-ui-cover.component';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeAll,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  zip,
} from 'rxjs';
import striptags from 'striptags';
import { RESPONSE } from '../../../express.tokens';

type MeetingJoinStateType =
  | 'loading'
  | 'auth'
  | 'joining'
  | 'opening'
  | 'waiting'
  | 'not_found'
  | 'finished'
  | 'failed'
  | 'canceled'
  | 'participants_limit';

type ExecuteActionType = 'join' | 'accept' | 'open' | 'force-open';

type MeetingViewType = 'meeting' | 'user_service' | 'user_service_contract';

const defaultPagination: IPagination = {
  page: 1,
  pageSize: 50,
  total: 0,
  totalPages: 0,
};

export interface MeetingCard {
  accountRoute: string[];
  author: User;
  authorFullName: string;
  type: MeetingTypeEnum;
  description: string;
  category: MeetingCategoryEnum;
  fullTitle: string;
  meetingRoute: string[];
  authorAvatar: OnUiCover;
  cover: OnUiCover;
  meetingBeginAt: Date;
  meetingEndAt: Date;
  meetingTimeBetween: string;
  meetingJoinRoute: string[];
  meetingUrl: string;
  subject: string;
  isNativeLanguage: boolean;
  level: string;
  title: string;
  classroomTitle: string;
  classroomLink: string;
  authorStats?: Partial<IAccountStats>;
  priceFormatted?: string;
}

type ConfirmationActionType = 'cancel' | 'leave';

@Component({
  selector: 'app-meeting-join-page',
  templateUrl: './meeting-join-page.component.html',
  styleUrls: ['./meeting-join-page.component.scss'],
})
export class MeetingJoinPageComponent extends ChangableComponent implements OnInit, OnDestroy {
  public me: User;
  public author: User;
  public meeting: Meeting;
  public meetingData: MeetingCard;
  public state: MeetingJoinStateType = 'loading';
  public loading = false;
  public joinProcessing = false;
  public acceptProcessing = false;
  public meetingCheckoutDialogOpened = false;
  public paidJoinSuccess = false;
  public conferenceUrl: string;
  public error;
  public errorDetails;
  public debug: boolean;
  public logs = [];
  public meetingServiceType = MeetingTypeEnum;
  public breadCrumbs: Array<{ title: string; link?: string }> = [];
  public meetingFormModal: boolean;
  public confirmationAlert: boolean;
  public copied: boolean;
  public confirmationId: string;
  public confirmationAction: ConfirmationActionType;
  public confirmationOptions: ConfirmationOptions;
  public meetingReview: ReviewListItem;
  public inviteModal = false;
  public readonly routes = EContentPanelRoute;
  public contracted: boolean;
  public userServiceDetailsModal = false;
  public userServiceDetailsFooterDisplay: boolean;
  public btnState: OnUiButtonState = 'default';

  public lockPayment = false;

  private meeting_id: string;
  private meeting_slug: string;
  private meetingLoaded$ = this.register(new Subject<boolean>());
  private reloadMeeting$ = this.register(new Subject<boolean>());
  private refreshMeetingInterval;
  private openConferenceInterval;
  private runOnceOnCheckStateDone: () => void;
  private originalUrl: string;
  private _participants: MeetingParticipant[] = [];
  private _author: User;
  private _classroom: Classroom;
  private _userService: UserService;
  private _meetingLoading = false;
  private _meetingMarkedForReloadLater = false;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private contextService: ContextService,
    private authService: AuthService,
    private store: Store,
    private billingV2Service: BillingV2Service,
    private route: ActivatedRoute,
    private router: Router,
    private meetingsService: MeetingsService,
    private userServicesService: UserServicesService,
    private commentService: CommentService,
    private accountService: AccountService,
    private classroomsService: ClassroomsService,
    private websocketService: WebsocketService,
    private loadingBar: LoadingBarService,
    private translate: TranslateService,
    private request: RequestService,
    private meta: MetaService,
    private location: Location,
    protected analytics: AnalyticsService,
    @Optional() @Inject(RESPONSE) private response: any,
    @Inject(DOCUMENT) private document: any,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);

    this.resetCard();
  }

  public ngOnInit() {
    of(
      this.watchMe$, // При логине/перелогине - перегрузить митинг
      this.watchRouteChanged$, // При смене роута - загрузить митинг по slug
      this.watchMeetingLoaded$, // Действия после загрузки митинга
      this.watchWsReconnected$, // При переподключении к веб-сокетам - перегрузить митинг
      this.watchReloadMeeting$,
    )
      .pipe(mergeAll())
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });

    // Загрузить митинг по текущему slug
    const _params = this.route.snapshot.paramMap;
    this.loadMeetingBySlug(_params.get('slug'));

    this.onBrowserOnly(() => {
      this.contextService.debug$.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (debug) => (this.debug = debug),
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });

      this.route.queryParamMap.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (queryParams) => {
          const actions: ExecuteActionType[] = ['join', 'accept', 'open'];
          const action = actions.find((a) => queryParams.has(a));

          // выполнить автоматически после checkState
          this.runOnceOnCheckStateDone = () => {
            this.executeAction(action);
          };

          this.checkState();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
    });
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
    this.stopWatching();
    this.meta.reset();
    this.runOnceOnCheckStateDone = null;
  }

  public get isDevTester(): boolean {
    return AccountService.hasRole(this.me, UserRoleEnum.developer, UserRoleEnum.tester);
  }

  public get participants(): MeetingParticipant[] {
    return this._participants.filter((participant) => {
      const isAccepted = [MeetingAcceptStatusEnum.not_required, MeetingAcceptStatusEnum.accepted].includes(
        participant.accept_status,
      );
      const isMe = participant.user_id === this.me?.id;
      return isAccepted || isMe;
    });
  }

  /**
   * При смене роута - загрузить митинг по slug
   */
  private get watchRouteChanged$() {
    return this.route.paramMap.pipe(
      map((params) => params.get('slug')),
      filter((slug: string) => slug !== this.meeting_slug),
      tap((slug: string) => this.loadMeetingBySlug(slug)),
    );
  }

  /**
   * При логине/перелогине - права пользователя могут поменяться
   */
  private get watchMe$(): Observable<User> {
    return this.contextService.me$.pipe(
      tap((me) => {
        const oldMeId = this.me?.id || null;
        const newMeId = me?.id || null;
        this.me = me;
        if (oldMeId && oldMeId !== newMeId) {
          // чтобы обновилась meeting.visit_info и meeting.participants
          this.markMeetingForReload();
        } else {
          this.checkState();
        }
      }),
    );
  }

  /**
   * Действия после загрузки митинга
   */
  private get watchMeetingLoaded$() {
    return this.meetingLoaded$.pipe(
      tap(() => {
        this.setupCard();
        this.setupMeta();
        this.detectChanges();
      }),
      switchMap(() => this.loadMeetingParticipants$()),
    );
  }

  private extendParticipantsPaged$(
    response: IPagedResults<MeetingParticipant[]>,
  ): Observable<IPagedResults<MeetingParticipant[]>> {
    const participants = response.results || [];
    const userIds = uniq(participants.map((p) => p.user_id));
    if (!userIds.length) {
      return of(response);
    }
    return this.accountService
      .findAccounts({ use: FindAccountStrategyEnum.id, id: userIds }, { page: 1, pageSize: userIds.length })
      .pipe(
        map((_response) => {
          const users = _response.results;
          participants.map((p) => {
            p.user = users.find((u) => u.id === p.user_id);
          });
          return { ...response, results: participants };
        }),
      );
  }

  private get watchReloadMeeting$(): Observable<any> {
    return this.reloadMeeting$.pipe(
      filter(() => !!this.meeting_id),
      debounceTime(1000),
      filter(() => {
        if (this._meetingLoading || this.joinProcessing || this.acceptProcessing || this.meetingCheckoutDialogOpened) {
          // не перегружать, если митинг уже в процессе загрузки или на этапе подключения к митингу
          // но запомнить, что митинг нужно будет перезагрузить позже
          this.markMeetingForReloadLater();
          return false;
        } else {
          this._meetingLoading = true;
          this.clearMarkMeetingForReloadLater();
          return true;
        }
      }),
      switchMap(() => this.loadMeeting$(this.meeting_id)),
      tap(() => {
        this.log(this.constructor.name + '.watchReloadMeeting$ -> loaded');
        this._meetingLoading = false;
        this.checkMeetingForReloadLater();
      }),
    );
  }

  /**
   * Загрузка митинга по параметрам из URL
   */
  private loadMeetingBySlug(meeting_slug: string) {
    if (!meeting_slug) {
      return;
    }
    if (this.loading && this.meeting_slug === meeting_slug) {
      return;
    }

    this.meeting_slug = meeting_slug;
    this.loading = true;
    this.loadingBar.fetchingStart(35);
    this.meetingsService
      .getMeetingBySlug(this.meeting_slug)
      .pipe(
        switchMap((meeting) => this.extendAuthor$(meeting)),
        tap((meeting) => {
          this.meeting_id = meeting.id;
          this.meeting = meeting;
          this.contracted = meeting.user_service_contract_id && !meeting.visit_info?.is_participant;
          this._userService = meeting.user_service as UserService;
          this._author = meeting.author;
          this._classroom = meeting.classroom;
          this.loading = false;
          this.meetingLoaded$.next(true);
          this.startWatching();
        }),
        switchMap(() =>
          zip(
            !this._classroom ? this.loadClassroom$(this.meeting?.classroom_id) : of(null),
            !this._userService ? this.loadUserService$(this.meeting?.user_service_id) : of(null),
            this.can('review') ? this.loadMyMeetingReview$() : of(null),
          ),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: () => {
          this.meetingLoaded$.next(true);
          this.checkState();
          this.loadingBar.fetchingComplete();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          if (error instanceof NotFoundError) {
            this.loading = false;
            this.loadingBar.fetchingStop();
          }
        },
      });
  }

  public trackByFn(index, user: User): string {
    return user.id;
  }

  public get userService() {
    return this._userService;
  }

  public can(action: EMeetingUserAction | string): boolean {
    if (!this.meeting || !this.meeting.can) {
      return false;
    }
    return !!this.meeting.can[action];
  }

  public is(action: EMeetingUserState | string): boolean {
    if (!this.meeting || !this.meeting.is) {
      return false;
    }
    return !!this.meeting.is[action];
  }

  public printDebugInfo() {
    logger.log({
      meeting: this.meeting,
      logs: this.logs,
    });
  }

  public executeAction(action: ExecuteActionType) {
    switch (action) {
      case 'join':
        if (this.can('join')) {
          return this.joinMeeting();
        }
        if (this.can('paidJoin')) {
          return this.joinMeeting();
        }
        break;

      case 'accept':
        if (this.can('acceptInvitation')) {
          return this.acceptMeeting();
        }
        if (this.can('paidAcceptInvitation')) {
          return this.joinMeeting();
        }
        break;

      case 'open':
        if (this.can('open') || this.can('autoOpen')) {
          if (this.conferenceUrl) {
            this.tryOpenConference();
          } else {
            this.prepareMeetingSession()
              .then(() => this.tryOpenConference())
              .catch((err) => this.handleActionError('MeetingJoinPageComponent:executeAction ' + action, err));
          }
        }
        break;

      case 'force-open':
        if (this.can('open')) {
          this.prepareMeetingSession()
            .then(() => this.openConference(true))
            .catch((err) => this.handleActionError('MeetingJoinPageComponent:executeAction ' + action, err));
        }
        break;
    }
  }

  /** Режим отображения митинга */
  public get meetingView(): MeetingViewType {
    if (!this.meeting?.user_service) {
      // отдельный, обычный митинг
      return 'meeting';
    }
    // иначе: митинг относится к конкретному контрату на услугу

    if (this.meeting?.is?.paidOrFreeParticipant) {
      // доступ конкретно к этому митингу оплачен
      return 'meeting';
    }

    // иначе: митинг не оплачен

    if (!!this.meeting?.user_service_contract?.visit_info?.unpaid_order) {
      // есть инвойс (ордер) на оплату и пока не оплачен
      return 'user_service_contract';
    }

    // нет инвойса (ордера) - значит вообще не записывался на услугу
    return 'user_service';
  }

  public openUserServiceDetailsModal() {
    this.userServiceDetailsModal = true;
    this.setupUrlByUserService();
    this.detectChanges();
  }

  public closeUserServiceDetailsModal() {
    this.userServiceDetailsModal = false;
    this.resetUrl();
    this.detectChanges();
  }

  public onDetailsModalViewportChange(visible: boolean) {
    this.userServiceDetailsFooterDisplay = visible;
    this.detectChanges();
  }

  private setupUrlByUserService() {
    const url = LibraryRouter.userServiceRouteUniversal(this.userService);
    const currentUrl = this.location.path(false);
    if (currentUrl !== url) {
      if (!this.originalUrl) {
        this.originalUrl = currentUrl;
      }
      this.location.replaceState(url);
    }
  }

  private resetUrl() {
    if (this.originalUrl) {
      this.location.replaceState(this.originalUrl);
      this.originalUrl = null;
    }
  }

  protected stopIntervals() {
    // остановить циклические перегрузки митинга
    if (this.meeting?.can?.open) {
      // наконец-то можно открыть
      /** @see startRefreshMeetingInterval() */
      this.clearInterval(this.refreshMeetingInterval);
    }
  }

  protected checkState(forceOpen = false) {
    this.state = null;

    if (!this.isBrowser || !this.meeting) {
      return;
    }

    this.stopIntervals();

    this.log('MeetingJoinPageComponent:checkState', '...');

    if (this.is('canceled')) {
      this.state = 'canceled';
      return;
    }

    if (this.is('participantsLimit')) {
      this.state = 'participants_limit';
      return;
    }

    if (this.can('authJoin') || this.can('authPaidJoin')) {
      this.log('MeetingJoinPageComponent:checkState', 'auth required');
      this.state = 'auth';
      // this.authService.showAuthModal(() => {
      //   if (this.runOnceOnCheckStateDone) {
      //     this.runOnceOnCheckStateDone();
      //     this.runOnceOnCheckStateDone = null;
      //   }
      // });
      return;
    }

    if (this.is('owner')) {
      if ((this.can('open') || this.can('autoOpen')) && forceOpen) {
        this.log('MeetingJoinPageComponent:checkState', 'execute open');
        return this.executeAction('open');
      }
    } else {
      if ((this.can('open') && forceOpen) || this.can('autoOpen')) {
        this.log('MeetingJoinPageComponent:checkState', 'execute open');
        return this.executeAction('open');
      }
    }

    for (const fieldName of [
      'acceptRequired',
      'paidAcceptRequired',
      'joinRequired',
      'paidJoinRequired',
      'participant',
    ]) {
      if (this.meeting?.is && this.meeting?.is[fieldName]) {
        this.state = 'waiting';
        break;
      }
    }

    if (this.state === 'waiting') {
      if (this.runOnceOnCheckStateDone) {
        this.runOnceOnCheckStateDone();
        this.runOnceOnCheckStateDone = null;
      }
    }
  }

  public authorize() {
    this.authService.showAuthModal(() => {
      this.btnState = 'progress';
      this.detectChanges();
    });
  }

  public joinMeeting(forceOpen = false) {
    if (!this.authService.isAuthenticated) {
      this.authService.showAuthModal(() => {
        this.onJoinMeeting(forceOpen);
      });
    } else {
      this.onJoinMeeting(forceOpen);
    }
  }

  public onJoinMeeting(forceOpen = false) {
    if (this.is('participant')) {
      this.log('MeetingJoinPageComponent:acceptMeeting', 'is already joined');
      this.onJoined(forceOpen);
      return;
    }

    if (this.joinProcessing) {
      return;
    }

    this.state = 'joining';
    this.joinProcessing = true;
    this.detectChanges();

    this.meetingsService
      .joinMeeting(this.meeting_id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.log('MeetingJoinPageComponent:joinMeeting', response);
          this.joinProcessing = false;
          if (response.model) {
            this.meeting = response.model;
            this.meetingLoaded$.next(true);
          }
          if (response.status) {
            this.onJoined(forceOpen);
          } else {
            this.handleActionError(
              'MeetingJoinPageComponent:joinMeeting',
              response.error || response.statusText,
              response.details,
            );
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.joinProcessing = false;
        },
      });
  }

  private onJoined(forceOpen = false) {
    this.checkMeetingForReloadLater();

    if (this.is('paidMeeting') && !this.is('paidOrFreeParticipant')) {
      // место занято, но не оплачено
      this.log('MeetingJoinPageComponent:onJoined', 'join success, payment required');
      // TODO success info

      this.meetingCheckoutDialogOpened = true; /** @see onPaymentSucceed */
    } else if (this.can('open')) {
      this.log('MeetingJoinPageComponent:onJoined', 'join done');
      this.checkState(forceOpen);
    } else {
      this.err('MeetingJoinPageComponent:onJoined', 'join failed', {
        visit_info: this.meeting.visit_info,
        dumpHelper: this.dumpHelper,
      });
      this.state = 'failed';
      this.error = 'wrong participant info';
      this.errorDetails = this.meeting.visit_info;
    }
    this.detectChanges();
  }

  // Успешно оплачен доступ к классу
  public onPaymentSucceed() {
    this.log('MeetingJoinPageComponent:onPaymentSucceed');
    this.meetingCheckoutDialogOpened = false;
    this.acceptProcessing = false;
    this.paidJoinSuccess = true;
    this.joinProcessing = false;
    this.lockPayment = true;
    this.acceptMeeting();
    this.startRefreshMeetingInterval();
    this.detectChanges();
  }

  public onPaymentCancel() {
    this.log('MeetingJoinPageComponent:onPaymentCancel');
    this.meetingCheckoutDialogOpened = false;
    if (!this.paidJoinSuccess) {
      this.checkState(); // повторить анализ статуса митинга
    } else {
      this.markMeetingForReload();
    }
    this.detectChanges();
  }

  private startRefreshMeetingInterval() {
    if (this.meeting?.can?.open) {
      this.checkState();
      return;
    }

    // Повторять запросы до тех пор, пока митинг не сможет быть открыт
    // также см. this.watchMeetingUpdated$

    this.state = 'joining';
    this.clearInterval(this.refreshMeetingInterval);
    this.refreshMeetingInterval = this.setInterval(() => {
      this.log('MeetingJoinPageComponent:refreshMeeting', ' -> markMeetingForReload()');
      this.markMeetingForReload();
    }, 2000);
  }

  /**
   * Принять приглашение в митинг
   */
  private acceptMeeting() {
    if (this.meeting?.is?.acceptedParticipant) {
      // приглашение принято
      this.log('MeetingJoinPageComponent:acceptMeeting', 'is already accepted');
      this.checkState();
      // ??? this.onAccepted();
      return;
    }

    if (this.acceptProcessing) {
      return;
    }

    this.state = 'joining';
    this.acceptProcessing = true;
    this.detectChanges();

    this.meetingsService
      .acceptMeeting(this.meeting_id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([meeting, participant]) => {
          this.acceptProcessing = false;
          this.log('MeetingJoinPageComponent:acceptMeeting', { meeting, participant });
          if (meeting) {
            this.meeting = meeting;
            this.meetingLoaded$.next(true);
          }
          this.onAccepted();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.acceptProcessing = false;
        },
      });
  }

  private onAccepted() {
    if (this.meeting?.is?.acceptedParticipant) {
      // приглашение принято
      this.log('MeetingJoinPageComponent:onAccepted', 'now accepted');
      this.checkState();
    } else {
      this.err('MeetingJoinPageComponent:onAccepted', 'not accepted');
      // ??? this.state = 'failed';
      // this.error = {
      //   message: 'wrong participant info',
      //   visit_info: this.meeting.visit_info,
      // };
    }
    this.detectChanges();
  }

  public handleActionError(source, error, details?) {
    this.err(source, error);
    this.state = 'failed';
    this.error = error?.message || String(error);
    this.errorDetails = details || error.stack;
    this.detectChanges();
  }

  /**
   * Попытка открыть митинг сейчас ИЛИ повторять каждые 15 секунд
   */
  private tryOpenConference() {
    clearInterval(this.openConferenceInterval);
    if (!this.openConference()) {
      // открыть митинг сейчас
      this.clearInterval(this.openConferenceInterval);
      this.openConferenceInterval = this.setInterval(() => {
        this.log('MeetingJoinPageComponent:tryOpenConference', 'retrying...');
        if (this.openConference()) {
          clearInterval(this.openConferenceInterval);
        }
      }, 15 * 1000); // ИЛИ повторять каждые 15 секунд
    }
  }

  /** Открыть класс */
  private openConference(forceOpen = false): boolean {
    if (this.meeting?.can?.autoOpen || this.meeting?.can?.open || forceOpen) {
      this.state = 'opening';
      this.log('MeetingJoinPageComponent:openConference', this.conferenceUrl);
      this.analytics.event(EventActionEnum.conference_opening, EventCategoryEnum.service);
      this.setTimeout(() => {
        // таймаут для передачи аналитики
        if (!this.debug) {
          this.document.location.href = this.conferenceUrl;
        }
      }, 250);
      return true;
    } else if (this.meeting?.can?.open) {
      this.state = 'waiting';
      return true;
    }
    return false;
  }

  private prepareMeetingSession(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.meetingsService
        .getMeetingSession(this.meeting_id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (session) => {
            if (session && session.participant && session.conference_url) {
              if (this.debug) {
                session.conference_url = session.conference_url + '&debug=true';
              }

              this.conferenceUrl = session.conference_url;
              resolve(true);
            } else {
              reject('No session or access denied');
            }
          },
          error: (error: any) => {
            this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
            reject(error);
          },
        });
    });
  }

  public get joinMeetingUrl(): string {
    if (this.meeting) {
      return this.request.host + this.router.createUrlTree(ContentRouter.meetingJoinRoute(this.meeting)).toString();
    }
  }

  public onReschedule() {
    this.store.dispatch(openMeetingRescheduleWindow({ meeting_id: this.meeting.id }));
  }

  private startWatching() {
    this.websocketService.startWatching('meeting', this.meeting_id);

    // При уведомлениях об изменении митинга - перегрузить митинг
    this.watchWsMeetingUpdated$.pipe(takeUntil(this.destroyed$)).subscribe({
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });
  }

  private stopWatching() {
    this.websocketService.stopWatching('meeting', this.meeting_id);
  }

  public get metaImageUrl(): string {
    return (
      FilesService.getFileBySize(this.meeting.cover, [ImageSizeEnum.wide, ImageSizeEnum.lg, ImageSizeEnum.md])?.url ||
      FilesService.getFileUrlBySize(this.meeting.cover_id, ImageSizeEnum.lg)
    );
  }

  private setupMeta() {
    const title = this.meetingsService.meetingFullTitle(this.meeting);
    this.meta.setTitle([title, 'meetings.meta-title']);
    this.meta.setDescription(striptags(this.meeting.description));
    this.meta.setKeywords(this.meeting.keywords || []);

    const contentRoute = ContentRouter.meetingJoinRoute(this.meeting);
    const url = this.router.createUrlTree(contentRoute).toString();
    this.meta.setUrl(url);

    this.onServerOnly(() => {
      // если страница открыта ботом - перенаправить на страницу митинга, потому что там больше мета-данных
      if (this.response) {
        this.response.redirect(301, contentRoute.join('/'));
      }
    });

    if (this.meeting.cover_id) {
      this.meta.setImage(this.meeting.cover_id);
    }
  }

  public clearError() {
    this.error = null;
    this.errorDetails = null;
    this.detectChanges();
  }

  public get dumpHelper(): any {
    return {
      state: this.state,
    };
  }

  protected log(...message) {
    if (environment.env === 'dev' || AccountService.hasRole(this.me, UserRoleEnum.developer) || this.debug) {
      logger.log(...message);
    }
    this.logs.push(message);
  }

  protected err(...message) {
    logger.error(...message);
    this.logs.push(message);
  }

  public get isPaidMeeting(): boolean {
    return this.meeting?.is?.paidMeeting;
  }

  public openInviteModal() {
    this.inviteModal = true;
    this.detectChanges();
  }

  public closeInviteModal() {
    this.inviteModal = false;
    this.detectChanges();
  }

  public updateInviteModal() {
    this.markMeetingForReload();
  }

  public leaveMeeting() {
    this.meetingsService
      .requestMeetingLeave(this.meeting.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          if (response.can) {
            this.confirmationAction = 'leave';
            this.confirmationId = response.confirmation_id;
            this.confirmationOptions = response.confirmation;
            this.openConfirmationAlert();
          } else {
            logger.warn("Can't leave meeting for now");
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public cancelMeeting() {
    this.meetingsService
      .requestMeetingCancel(this.meeting.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          if (response.can) {
            this.confirmationAction = 'cancel';
            this.confirmationId = response.confirmation_id;
            this.confirmationOptions = response.confirmation;
            this.openConfirmationAlert();
          } else {
            logger.warn("Can't cancel meeting for now");
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public openConfirmationAlert() {
    this.confirmationAlert = true;
    this.detectChanges();
  }

  public closeConfirmationAlert() {
    this.confirmationAlert = false;
    this.detectChanges();
  }

  public confirm() {
    switch (this.confirmationAction) {
      case 'leave':
        return this.leaveMeetingConfirm();

      case 'cancel':
        return this.cancelMeetingConfirm();
    }
  }

  public leaveMeetingConfirm() {
    this.meetingsService
      .confirmMeetingLeave(this.meeting.id, this.confirmationId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.meeting = response.model;
          this.setupCard();
          this.closeConfirmationAlert();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public cancelMeetingConfirm() {
    this.meetingsService
      .confirmMeetingCancel(this.meeting.id, this.confirmationId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.meeting = response.model;
          this.setupCard();
          this.closeConfirmationAlert();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  private resetCard() {
    this.meetingData = {
      accountRoute: [],
      author: undefined,
      authorAvatar: undefined,
      description: '',
      authorFullName: '',
      type: MeetingTypeEnum.not_defined,
      category: MeetingCategoryEnum.personal,
      cover: undefined,
      fullTitle: '',
      isNativeLanguage: false,
      level: '',
      meetingBeginAt: undefined,
      meetingEndAt: undefined,
      meetingTimeBetween: undefined,
      meetingJoinRoute: [],
      meetingRoute: [],
      meetingUrl: '',
      subject: '',
      title: '',
      classroomTitle: '',
      classroomLink: '',
    };
  }

  public get isGuest(): boolean {
    return this.is('guest');
  }

  public typeLabel(type: MeetingTypeEnum): string {
    switch (type) {
      case MeetingTypeEnum.single:
        return 'meetings.types.single';
      case MeetingTypeEnum.group:
        return 'meetings.types.group';
      case MeetingTypeEnum.webinar:
        return 'meetings.types.webinar';
      case MeetingTypeEnum.stream:
        return 'meetings.types.stream';
      case MeetingTypeEnum.talk:
        return 'meetings.types.talk';
      default:
        return '';
    }
  }

  public categoryLabel(category: MeetingCategoryEnum): string {
    switch (category) {
      case MeetingCategoryEnum.personal:
        return 'meetings.titles.personal';
      case MeetingCategoryEnum.speaking:
        return 'meetings.titles.speaking';
      case MeetingCategoryEnum.exam_training:
        return 'meetings.titles.exam_training';
      case MeetingCategoryEnum.for_children:
        return 'meetings.titles.for_children';
      default:
        return '';
    }
  }

  public get isMyClass() {
    return this.me?.id === this.meeting?.author_id;
  }

  protected setupCard() {
    this.meetingData.type = this.meeting?.type;
    this.meetingData.category = this.meeting?.category_id;
    this.meetingData.description = this.meeting?.description;
    this.meetingData.fullTitle = this.meetingsService.meetingFullTitle(this.meeting);
    this.meetingData.meetingRoute = ContentRouter.meetingRoute(this.meeting);
    this.meetingData.cover = {
      type: 'cover',
      img:
        FilesService.getFileBySize(this.meeting?.cover, [ImageSizeEnum.wide, ImageSizeEnum.md])?.url ||
        FilesService.getFileUrlBySize(this.meeting.cover_id, ImageSizeEnum.wide),
      title: this.meetingData.fullTitle,
      aspect: '16/9',
    };
    this.meetingData.meetingBeginAt = new Date(this.meeting?.begin_at);
    this.meetingData.meetingEndAt = addSeconds(new Date(this.meeting?.end_at), 1);
    this.meetingData.meetingTimeBetween = String(
      differenceInMinutes(this.meetingData.meetingEndAt, this.meetingData.meetingBeginAt),
    );
    this.meetingData.meetingJoinRoute = ContentRouter.meetingJoinRoute(this.meeting);
    this.meetingData.meetingUrl =
      this.request.host + this.router.createUrlTree(this.meetingData.meetingRoute).toString();
    this.meetingData.subject = this.meeting?.subject ? this.meeting.subject?.title : null;
    this.meetingData.isNativeLanguage = this.meeting?.subject?.category_id === SubjectCategoryEnum.native_language;
    this.meetingData.level = this.meeting.level ? this.meeting.level.title : 'subject-levels.any-level';
    this.meetingData.title = this.meeting?.title;
    if (this.isPaidMeeting) {
      this.billingV2Service
        .formatAmount$(this.meeting.price_tier)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (priceTier) => {
            this.meetingData.priceFormatted = priceTier;
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          },
        });
    }
    this.setupCardByClassroom();
    this.setupCardByAuthor();
  }

  private setupCardByClassroom() {
    if (this._classroom) {
      this.meetingData.classroomTitle = this._classroom.title;
      this.meetingData.classroomLink = this.router
        .createUrlTree(ClassroomsService.classroomRoute(this._classroom))
        .toString();
    }
    this.generateBreadcrumbs();
  }

  private setupCardByAuthor() {
    if (this._author) {
      this.meetingData.accountRoute = AccountService.accountRoute(this._author);
      this.meetingData.authorFullName = AccountService.getUserName(this._author);
      this.meetingData.authorStats = this._author.stats;
      this.meetingData.authorAvatar = {
        type: 'avatar',
        img: FilesService.getFileUrlBySize(this._author.userpic_id, ImageSizeEnum.sm),
        title: this.meetingData.authorFullName,
        link: this.router.createUrlTree(this.meetingData.accountRoute).toString(),
        form: 'circle',
      };
    }
  }

  public get showInformWindow() {
    return (
      this.is('canceled') ||
      this.is('joinRequired') ||
      this.is('acceptRequired') ||
      this.is('paidJoinRequired') ||
      this.is('paidAcceptRequired') ||
      this.is('participantLimit') ||
      (!this.can('open') && this.is('finished')) ||
      this.isMyClass ||
      this.contracted
    );
  }

  public get informWindowText() {
    if (this.contracted) {
      if (this.userService?.type === EUserServiceType.regular) {
        return 'To become a member of the regular class, click the Subscribe button';
      } else if (this.userService?.type === EUserServiceType.mini) {
        return 'To become a member of the mini-course, click the Book Now button';
      } else if (this.userService?.type === EUserServiceType.course) {
        return 'To become a member of the course, click the Book Now button';
      } else if (this.userService?.type === EUserServiceType.single) {
        return 'To become a member of the class, click the Book Now button';
      }
    } else if (this.is('canceled')) {
      if (this.meeting.has.reservationExpireDate) {
        return 'meetings.join.book-again-title';
      } else {
        return 'meetings.join.canceled-meeting-title';
      }
    } else if (this.is('joinRequired') || this.is('acceptRequired')) {
      return 'meetings.join.meeting-title';
    } else if (this.is('paidJoinRequired') || this.is('paidAcceptRequired')) {
      return 'meetings.join.paid-meeting-title';
    } else if (this.is('participantLimit')) {
      return 'meetings.join.participants_limit-meeting-title';
    } else if (!this.can('open') && this.is('finished')) {
      return 'meetings.join.finished-meeting-title';
    }
  }

  public get userServiceView(): ServiceCardDisplayModeType {
    return this.deviceService.isMobile() ? 'infinity' : 'narrow2';
  }

  // WTF ???
  // public get canBookAgain(): boolean {
  //   return !!(this.is('canceled') && this.meeting.has.reservationExpireDate);
  // }

  public onToggleMeetingForm() {
    this.meetingFormModal = !this.meetingFormModal;
  }

  protected generateBreadcrumbs() {
    this.breadCrumbs = [];
    this.breadCrumbs.push({
      title: this.translate.instant('library.breadcrumbs.home'),
      link: '/',
    });
    this.breadCrumbs.push({
      title: this.translate.instant('classrooms.page-header-title'),
      link: '/my-classes/classrooms',
    });
    this.breadCrumbs.push({
      title: this.meetingData?.classroomTitle,
      link: this.meetingData?.classroomLink,
    });
    this.breadCrumbs.push({
      title: this.meetingData?.title,
      link: null,
    });
  }

  protected markMeetingForReload() {
    this.reloadMeeting$.next(true);
    /** @see watchReloadMeeting$ */
  }

  protected markMeetingForReloadLater() {
    this.log(this.constructor.name + '.markMeetingForReloadLater', '-> marked meeting to RELOAD LATER');
    this._meetingMarkedForReloadLater = true;
  }

  protected clearMarkMeetingForReloadLater() {
    this.log(this.constructor.name + '.clearMarkMeetingForReloadLater', '-> cleared mark meeting to RELOAD LATER');
    this._meetingMarkedForReloadLater = false;
  }

  protected checkMeetingForReloadLater() {
    if (this._meetingMarkedForReloadLater) {
      this.log(
        this.constructor.name + '.checkMeetingForReloadLater',
        '-> meeting was marked to RELOAD LATER -> reload now',
      );
      this._meetingMarkedForReloadLater = false;
      this.markMeetingForReload();
    }
  }

  protected loadMeeting$(meeting_id: string) {
    return this.meetingsService.getMeetingById(meeting_id).pipe(
      tap((meeting) => {
        this.meeting = meeting;
        this.meetingLoaded$.next(true);
        this.lockPayment = false;
        this.btnState = 'default';
        this.checkState();
        this.detectChanges();
      }),
      catchError((err) => {
        this.err('MeetingJoinPageComponent:loadMeetingById', err);
        this.btnState = 'default';
        // TODO разобраться с Sentry : Sentry.captureException(err);
        return of(null);
      }),
    );
  }

  protected loadMyMeetingReview$() {
    return this.commentService.getMyMeetingReview(this.meeting_id).pipe(
      tap((meetingReview) => (this.meetingReview = meetingReview)),
      catchError((err) => {
        this.err('loadMyMeetingReview$', err);
        return of(null);
      }),
    );
  }

  private loadClassroom$(id: string) {
    if (this.isGuest) {
      // WTF???
      return of(null);
    }
    if (!id) {
      return of(null);
    }
    return this.classroomsService.getClassroomById(id, defaultClassroomDetails).pipe(
      tap((classroom) => {
        this._classroom = classroom;
        this.setupCardByClassroom();
      }),
      catchError((err) => {
        this.err('loadClassroom$', err);
        return of(null);
      }),
    );
  }

  private loadMeetingParticipants$() {
    return this.meetingsService.getMeetingParticipants(this.meeting_id, defaultPagination).pipe(
      switchMap((response) => this.extendParticipantsPaged$(response)),
      tap((response) => (this._participants = response.results)),
      catchError((err) => {
        this.err(this.constructor.name + 'loadMeetingParticipants$()', err);
        return of(null);
      }),
    );
  }

  private loadUserService$(id: string) {
    if (!id) {
      return of(null);
    }
    return this.userServicesService.getById(id, fullUserServiceDetails).pipe(
      switchMap((userService) => this.extendAuthor$(userService)),
      tap((userService) => (this._userService = userService)),
      catchError((err) => {
        this.err(this.constructor.name + 'loadUserService$()', err);
        return of(null);
      }),
    );
  }

  private extendAuthor$<T extends { author_id: string; author?: User }>(entity: T): Observable<T> {
    if (!entity || !entity.author_id || entity.author) {
      return of(entity);
    }
    return this.accountService.getUserById(entity.author_id, 'LOAD_TIME').pipe(
      map((account) => {
        // logger.debug('CachedService shared$(' + entity.author_id + ') -> value$ ready');
        entity.author = account;
        return entity;
      }),
      catchError((err) => {
        this.err(this.constructor.name + '.extendAuthor$()', err);
        return of(entity);
      }),
    );
  }

  /** Обновление митинга при реконнекте к веб-сокетам */
  protected get watchWsReconnected$() {
    return this.websocketService.status$.pipe(
      debounceTime(1000),
      tap((isOnline) => {
        if (isOnline) {
          this.log(this.constructor.name + '.watchWsReconnected$ -> online -> markMeetingForReload()');
          this.markMeetingForReload();
        }
      }),
    );
  }

  protected get watchWsMeetingUpdated$() {
    return this.websocketService.onEntityUpdate('meeting', this.meeting_id).pipe(
      tap(() => {
        this.log(this.constructor.name + '.watchWsMeetingUpdated$ -> markMeetingForReload()');
        this.markMeetingForReload();
      }),
    );
  }

  public onCopied() {
    this.copied = true;
    this.markForCheck();

    this.setTimeout(() => {
      this.copied = false;
      this.markForCheck();
    }, 1000);
  }
}
