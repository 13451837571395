<div class="attachment-uploader">
  <div class="dropzone dropzone-container" *ngIf="!cover || cover_uploading"
       [class.no-cover]="!cover"
       [dropzone]="dropzoneConfig"
       (init)="onUploadInit($event)"
       (sending)="onUploadStart($event)"
       (uploadProgress)="onUploadProgress($event)"
       (error)="onUploadError($event)"
       (success)="onUploadSuccess($event)">
    <div class="dz-default dz-message">
      <ng-template [ngIf]="!cover_uploading">
        <app-icon
          class="attach-icon"
          [width]="30"
          [height]="30"
          [icon]="'plus'">
        </app-icon>
      </ng-template>

      <ng-template [ngIf]="cover_uploading">
        <spinner1></spinner1>
      </ng-template>
    </div>
  </div>

  <div class="dropzone dropzone-container" *ngIf="cover && !cover_uploading">
    <div class="dz-default dz-cover">
      <a [href]="cover_file?.storage_file_url" target="_blank">
        <on-ui-cover *ngIf="!isDocument()" [cover]="cover" [withBlur]="true"></on-ui-cover>
        <pdf-viewer *ngIf="isDocument()"
                    [src]="cover_file?.storage_file_url"
                    [original-size]="false"
                    style="width: 230px; height: 140px"
        ></pdf-viewer>
      </a>
    </div>
    <p class="delete-link" (click)="onRemoved($event)">Удалить</p>
  </div>
</div>

<on-ui-progress-bar [value]="percent"></on-ui-progress-bar>
