import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { logger } from '@core/helpers/logger';
import { DropzoneConfig, FilesService, IFileType } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { FilePurposeEnum, FileTypeEnum, ImageSizeEnum } from 'lingo2-models';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { OnUiCover } from 'onclass-ui';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-certificate-uploader',
  templateUrl: './certificate-uploader.component.html',
  styleUrls: ['./certificate-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CertificateUploaderComponent),
      multi: true,
    },
  ],
})
export class CertificateUploaderComponent extends ChangableComponent implements OnInit, ControlValueAccessor {
  @Input() last: boolean;
  @Output() changed = new EventEmitter<string>();
  public dropzoneConfig: DropzoneConfigInterface = {
    paramName: 'file',
    uploadMultiple: false,
    clickable: true,
    autoReset: 1,
    maxFiles: 10,
    errorReset: null,
    cancelReset: null,
    dictDefaultMessage: '',
    url: '/',
  };
  public cover_file: IFileType;
  public cover_uploading = false;
  public formControl = new UntypedFormControl();
  public value = '';
  public percent = 0;
  private propagateChange: any = () => {};
  private propagateTouched: any = () => {};

  constructor(
    public errorNotificationService: ErrorNotificationService,
    protected filesService: FilesService,
    protected dzConfig: DropzoneConfig,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  public ngOnInit(): void {
    this.configureDropzone();
  }

  public writeValue(value: string): void {
    this.value = value;
    this.formControl.setValue(value);
    this.loadPic(this.value);
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public isDocument() {
    return this.cover_file.type === FileTypeEnum.document;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {}

  public onChange(value: string): void {
    this.value = value;
    this.propagateChange(value);
    this.changed.emit(value);
    this.cdr.markForCheck();
  }

  public onUploadInit(args: any): void {}

  public onUploadStart(args: any): void {
    this.cover_uploading = true;
  }

  public onUploadProgress(args: any): void {
    logger.log('dropzone:onUploadProgress', args);
    const [, file] = args;
    this.percent = file;
  }

  public onUploadError(args: any): void {
    this.cover_uploading = false;
  }

  public onUploadSuccess(args): void {
    const [, cover] = args;
    this.cover_file = cover;
    this.onChange(cover.id);
    this.loadPic(cover.id, true);
    this.cover_uploading = false;
  }

  public get cover(): OnUiCover {
    if (!this.cover_file) {
      return null;
    }
    return {
      type: 'cover',
      img: FilesService.getFileUrlBySize(this.cover_file.id, ImageSizeEnum.wide),
      form: 'base',
    };
  }

  protected loadPic(id: string, forceReload = false) {
    if (!id) {
      this.cover_file = null;
      return;
    }
    if (!forceReload && this.cover_file && this.cover_file.id === id) {
      return;
    }

    this.filesService
      .getFileById(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (file) => {
          this.cover_file = file;
          this.markForCheck();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-FILE');
          this.cover_file = null;
          this.markForCheck();
        },
      });
  }

  public onRemoved(args) {
    logger.log('onRemoved', args);
    this.cover_file = null;
    this.onChange(null);
  }

  protected configureDropzone() {
    this.dropzoneConfig = this.dzConfig.combine(this.dropzoneConfig, {
      url: this.dzConfig.url + '/file/upload+process?purpose=' + FilePurposeEnum.content, // особый URL для загрузки
    });
  }
}
