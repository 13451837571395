<ng-template [ngIf]="meeting"
             [ngIfThen]="meetingRef"
             [ngIfElse]="meetingPlaceholderRef">
</ng-template>

<ng-template #meetingPlaceholderRef>
  <app-skeleton class="meeting-skeleton" [class.mobile]="deviceService.isMobile()" *ngIf="loading" [stats]="'meeting'"></app-skeleton>
  <app-content-not-found *ngIf="!loading" [title]="'meetings.join.not_found'"></app-content-not-found>
</ng-template>

<ng-template #meetingRef>
  <ng-container *ngTemplateOutlet="breadcrumbs"></ng-container>
  <ng-container *ngTemplateOutlet="meetingCardRef"></ng-container>
</ng-template>

<ng-template #breadcrumbs>
    <nav class="bread-crumbs" [class.desktop]="deviceService.isDesktop()" [class.mobile]="deviceService.isMobile()">
    <div class="scroll">
      <ul class="navigation-list"
          itemscope itemtype="https://schema.org/BreadcrumbList">
        <li *ngFor="let item of breadCrumbs; let i = index, let last = last; trackBy: trackByFn"
            class="navigation-item"
            itemprop="itemListElement" itemscope
            itemtype="https://schema.org/ListItem">
          <a [routerLink]="item.link"
             class="navigation-link"
             [class.active]="last"
             [attr.data-test]="'breadcrumbs_' + item.title"
             [attr.alt]="item.title"
             [title]="item.title"
             itemprop="item">
            <span itemprop="name" class="label-navigation normal">{{ item.title }}</span>
            <app-icon width="6" height="10" icon="right" file="chevron"></app-icon>
            <meta itemprop="position" content="{{ i + 1 }}"/>
          </a>
        </li>
      </ul>
    </div>
  </nav>
</ng-template>

<ng-template #meetingCardRef>
  <div class="meeting-card-wrapper"
       [class.tablet]="deviceService.isTablet()"
       [class.mobile]="deviceService.isMobile()">
    <div class="meeting-card-info" *ngIf="showInformWindow" [ngClass]="{'my-class': isMyClass}">
      <div class="info" *ngIf="!isMyClass">
        <h2 class="title text-center">{{ informWindowText | translate }}</h2>
      </div>

      <div class="info" *ngIf="isMyClass">
        <div class="image">
          <app-icon width="50" height="50" file="emoji" icon="party"></app-icon>
        </div>
        <div class="text">
          <div class="title">{{'meetings.join.invite_link' | translate}}</div>
          <div class="more-wrap">
            <div class="link">{{joinMeetingUrl}}</div>
            <div class="copy" #copy>
              <span [ngxClipboard]
                    [cbContent]="joinMeetingUrl"
                    [container]="copy">{{'meetings.join.copy_link' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container [ngSwitch]="meetingView">
      <ng-container *ngSwitchCase="'meeting'">
        <ng-container *ngTemplateOutlet="meetingCardRef"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'user_service'">
        <app-user-service-card-2
          [displayMode]="userServiceView"
          [service]="userService"
          (openUserServiceInModal)="openUserServiceDetailsModal()"
        ></app-user-service-card-2>
      </ng-container>

      <ng-container *ngSwitchCase="'user_service_contract'">
        <app-user-service-contract-card
          [displayMode]="userServiceView"
          [user_service_contract]="meeting?.user_service_contract"
        ></app-user-service-contract-card>
      </ng-container>
    </ng-container>

    <ng-template #meetingCardRef>
      <div class="new-meeting-card">
        <div class="tools">
          <div class="copy-informer" [class.active]="copied"><span>{{'invite-card.link-copied' | translate}}</span></div>
          <app-subscribe [user_id]="meeting.author_id"></app-subscribe>
          <app-bookmark [item]="meeting"></app-bookmark>
          <on-ui-card-menu>
            <a class="menu-item warn" *ngIf="meeting.purl" [href]="meeting.purl">[⚠️] Panel [meeting]</a>
            <a class="menu-item warn" *ngIf="meeting.user_service?.purl" [href]="meeting.user_service.purl">[⚠️] Panel [service]</a>
            <a class="menu-item warn" *ngIf="meeting.user_service_contract?.purl" [href]="meeting.user_service_contract.purl">[⚠️] Panel [contract]</a>
            <div class="menu-item" *ngIf="debug" (click)="printDebugInfo()">DEBUG</div>
            <span class="menu-item"
                  *ngIf="can('edit')"
                  (click)="onToggleMeetingForm()">{{ 'meetings.menu-edit' | translate }}</span>
            <a class="menu-item"
               *ngIf="can('reschedule')"
               (click)="onReschedule()">{{ 'meetings.menu-reschedule' | translate }}</a>
            <div class="menu-item" #shareLinkRef>
              <button type="button"
                      ngxClipboard
                      [cbContent]="joinMeetingUrl"
                      (cbOnSuccess)="onCopied()"
                      [container]="shareLinkRef">{{ 'meetings.menu-share' | translate }}<!-- Поделиться --></button>
            </div>
            <div class="menu-item"
                 *ngIf="can('invite')"
                 (click)="openInviteModal()">{{ 'meetings.menu-invite' | translate }}<!--Пригласить--></div>
            <div class="menu-item warn"
                 *ngIf="can('leave') || can('leaveWithPenalty')"
                 (click)="leaveMeeting()">{{ 'meetings.menu-leave-meeting' | translate }}<!--Покинуть--></div>
            <div class="menu-item warn"
                 *ngIf="can('cancel') || can('cancelWithPenalty')"
                 (click)="cancelMeeting()">{{ 'meetings.menu-cancel-meeting' | translate }}<!--Отменить--></div>
          </on-ui-card-menu>
        </div>
        <div class="teacher-block-for-mobile">
          <div class="teacher-wrap">
            <div class="avatar-wrap">
              <on-ui-cover [cover]="meetingData?.authorAvatar"></on-ui-cover>
            </div>
            <span class="teacher-name">{{ meetingData?.authorFullName }}</span>
            <app-rating [stats]="meetingData?.authorStats"></app-rating>
          </div>
        </div>
        <div class="main-info">
          <div class="type"
               [ngClass]="meetingServiceType[meetingData?.type]">{{ typeLabel(meetingData?.type) | translate }}</div>
          <div class="poster">
            <on-ui-cover *ngIf="meetingData?.cover" [cover]="meetingData?.cover"></on-ui-cover>
            <on-ui-cover *ngIf="!meetingData?.cover" [cover]="meetingData?.authorAvatar"></on-ui-cover>
          </div>
          <div class="classes-info-wrapper">
            <div class="category"
                 *ngIf="meetingData?.category">{{categoryLabel(meetingData?.category) | translate }}</div>
            <div class="subject">{{meetingData?.subject}}</div>
            <div class="title"><app-meeting-title [meeting]="meeting"></app-meeting-title></div>
            <div class="teacher-block">
              <div class="teacher-wrap">
                <div class="avatar-wrap">
                  <on-ui-cover [cover]="meetingData?.authorAvatar"></on-ui-cover>
                </div>
                <span class="teacher-name">{{ meetingData?.authorFullName }}</span>
                <app-rating [stats]="meetingData?.authorStats"></app-rating>
              </div>
            </div>

            <div class="second-info">
              <ul>
                <li *ngIf="meetingData?.type">{{ typeLabel(meetingData?.type) | translate }}</li>
                <li>{{meetingData?.meetingBeginAt | dfnsFormat: 'dd MMMM'}}</li>
                <li>{{meetingData?.meetingBeginAt | zonedDate: me?.timezone |  dfnsFormat: 'HH:mm'}}</li>
                <li>{{meetingData?.meetingTimeBetween}} {{'meetings.join.min' | translate}}</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="second-info-for-mobile">
          <ul>
            <li *ngIf="meetingData?.type">{{ typeLabel(meetingData?.type) | translate }}</li>
            <li *ngIf="meetingData?.level">{{meetingData?.level | translate}}</li>
            <li>{{meetingData?.meetingBeginAt | dfnsFormat: 'dd MMMM'}}</li>
            <li>{{meetingData?.meetingBeginAt | zonedDate: me?.timezone | dfnsFormat: 'HH:mm'}}</li>
            <li>{{meetingData?.meetingTimeBetween}} {{'meetings.join.min' | translate}}</li>
          </ul>
        </div>
        <div *ngIf="meetingData?.description" class="meeting-description">{{meetingData?.description}}</div>
        <div class="meeting-footer">
          <div class="price-block">
            <div class="current-price" *ngIf="isPaidMeeting">
              <span class="price">{{ meetingData?.priceFormatted }}</span>
              <span class="period">{{ 'meetings.join.per_meet' | translate }}</span>
            </div>
          </div>

          <div class="action-wrap">
            <ng-template [ngIf]="isGuest" [ngIfElse]="altCase3Ref">
              <on-ui-button [state]="btnState"
                            class="primary"
                            (click)=" btnState === 'default' ? authorize() : null"
                            label="meetings.join-action">
              </on-ui-button>
            </ng-template>
            <ng-template #altCase3Ref>
              <ng-template [ngIf]="is('participantLimit')" [ngIfElse]="altCase4Ref">
                <on-ui-button class="status"
                              label="meetings.join.no-more-places-to-join">
                </on-ui-button>
              </ng-template>
              <ng-template #altCase4Ref>
                <a *ngIf="can('join')"
                   (click)="executeAction('join')">
                  <on-ui-button [state]="joinProcessing ? 'progress' : 'default'"
                                class="primary" [class.disabled]="joinProcessing"
                                label="meetings.join-action">
                  </on-ui-button>
                </a>
                <a *ngIf="can('paidJoin')"
                   (click)="joinMeeting()">
                  <on-ui-button [state]="lockPayment ? 'progress' : 'default'"
                                class="secondary" [class.disabled]="lockPayment"
                                label="meetings.paid-join-action">
                  </on-ui-button>
                </a>
                <a *ngIf="can('acceptInvitation')"
                   (click)="executeAction('accept')">
                  <on-ui-button [state]="joinProcessing ? 'progress' : 'default'"
                                class="primary" [class.disabled]="joinProcessing"
                                label="meetings.join.accept-meeting">
                  </on-ui-button>
                </a>
                <a *ngIf="can('paidAcceptInvitation')"
                   (click)="joinMeeting()">
                  <on-ui-button [state]="lockPayment ? 'progress' : 'default'"
                                class="primary" [class.disabled]="lockPayment"
                                label="meetings.join.accept-paid-meeting">
                  </on-ui-button>
                </a>
                <a *ngIf="can('open')"
                   (click)="joinMeeting(true)">
                  <on-ui-button class="primary"
                                label="meetings.open-action">
                  </on-ui-button>
                </a>

                <p *ngIf="lockPayment" class="payment-in-process-hint">{{'meetings.join.payment-in-process' | translate}}</p>

              </ng-template>
            </ng-template>
          </div>
        </div>
        <div *ngIf="debug && conferenceUrl">
          <a class="link-debug" href="{{conferenceUrl}}" target="_blank">{{conferenceUrl}}</a>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="can('review') && meetingReview">
      <app-meeting-review-form [meetingReview]="meetingReview"></app-meeting-review-form>
    </ng-template>

    <div class="participants-wrap" [class.mobile]="deviceService.isMobile()" *ngIf="meeting?.is?.participant && participants.length">
      <div class="title">
        <app-title [title]="'classrooms.classroom-page.tabs.participants'"></app-title>
      </div>
      <div class="list">
        <div class="user-card" *ngFor="let participant of participants; trackBy: trackByFn">
          <app-user-card-mini *ngIf="participant.user" [user]="participant.user"></app-user-card-mini>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<app-alert-single *ngIf="error" type="warning">
  <ng-container alert-title>{{ 'media-gallery.warning-title' | translate }}</ng-container>
  <ng-container alert-body>
    <div class="error">{{ 'meetings.join.error' | translate }}</div>
    <div class="error">{{ error }}</div>
    <pre class="error" *ngIf="(isDevTester || debug) && !!errorDetails">{{ errorDetails | json }}</pre>
  </ng-container>
  <ng-container alert-buttons>
    <div class="btn" (click)="clearError()">
      {{ 'media-gallery.warning-button' | translate }}
    </div>
  </ng-container>
</app-alert-single>

<ng-template [ngIf]="meetingCheckoutDialogOpened">
  <app-meeting-checkout-wizard-dialog
    [meetingId]="meeting.id"
    (succeed)="onPaymentSucceed()"
    (closed)="onPaymentCancel()"
  ></app-meeting-checkout-wizard-dialog>
</ng-template>

<app-modal *ngIf="inviteModal" [schedule]="true" [canClose]="false"
           (closed)="closeInviteModal()">
  <app-modal-body>
    <app-invite-to-meeting [meeting]="meeting" (invited)="updateInviteModal()"></app-invite-to-meeting>
  </app-modal-body>
</app-modal>

<ng-template [ngIf]="meetingFormModal">
  <app-modal *ngIf="deviceService.isDesktop(); else mobileMeetingForm"
             [canClose]="false"
             (closed)="onToggleMeetingForm()">
    <app-modal-body>
      <app-meeting-form-wrapper [meetingId]="meeting.id" [caller]="'app-meeting-join-page'"></app-meeting-form-wrapper>
    </app-modal-body>
  </app-modal>

  <ng-template #mobileMeetingForm>
    <app-mobile-modal (closed)="onToggleMeetingForm()">
      <app-mobile-modal-title>{{ 'lingo2-forms.meetings.form-title' | translate }}</app-mobile-modal-title>
      <app-mobile-modal-body>
        <app-meeting-form-wrapper [meetingId]="meeting.id" [caller]="'app-meeting-join-page'"></app-meeting-form-wrapper>
      </app-mobile-modal-body>
    </app-mobile-modal>
  </ng-template>
</ng-template>

<ng-template [ngIf]="userServiceDetailsModal">
  <app-modal *ngIf="deviceService.isDesktop()"
             [canClose]="false"
             (closed)="closeUserServiceDetailsModal()">
    <app-modal-body>
      <app-user-service-card-full [userService]="userService"
                                  [modal]="true"
                                  (inViewportChange)="onDetailsModalViewportChange($event)"
                                  (isCloseAllModals)="closeUserServiceDetailsModal()">
      </app-user-service-card-full>
    </app-modal-body>
    <app-modal-ad>
      <ng-template [ngIf]="!userServiceDetailsFooterDisplay">
        <app-user-service-surface-block [userService]="userService"></app-user-service-surface-block>
      </ng-template>
    </app-modal-ad>
  </app-modal>

  <app-mobile-modal *ngIf="!deviceService.isDesktop()"
                    [titleVisible]="true"
                    (closed)="closeUserServiceDetailsModal()">
    <app-mobile-modal-body>
      <app-user-service-card-full [userService]="userService" [modal]="true"></app-user-service-card-full>
    </app-mobile-modal-body>
  </app-mobile-modal>
</ng-template>

<app-alert *ngIf="confirmationAlert && confirmationOptions"
           [type]="confirmationOptions.type"
           [btnPosition]="'center'">
  <ng-container *ngIf="confirmationOptions.icon" alert-icon>
    <app-icon width="34" height="36" file="emoji" icon="think"></app-icon>
  </ng-container>
  <ng-container alert-title>{{confirmationOptions.title | translate}}</ng-container>
  <ng-container alert-body>{{confirmationOptions.description | translate}}</ng-container>
  <ng-container alert-buttons>
    <on-ui-button label="{{ confirmationOptions.close_button_text }}"
                  class="transparent"
                  (onclick)="closeConfirmationAlert()">
    </on-ui-button>
    <on-ui-button label="{{ confirmationOptions.confirm_button_text }}"
                  class="primary center"
                  (onclick)="confirm()">
    </on-ui-button>
  </ng-container>
</app-alert>
