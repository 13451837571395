import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CanComponentDeactivate } from '@core/guards/can-deactivate.guard';
import { logger } from '@core/helpers/logger';
import {
  ContextService,
  ProfileService,
  MetaService,
  PlatformService,
  IChangeResult,
  AccountService,
  ConfigService,
  LanguageService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { Country, User } from 'lingo2-models';
import { Config } from 'ng-otp-input/lib/models/config';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CountryISO } from 'ngx-intl-phone-input';
import { ToastrService } from 'ngx-toastr';
import { OnUiButtonState } from 'onclass-ui';
import { Observable, of } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { ProfileFormService } from '../_services/profile-form.service';
import { getCountries } from './../../../store/reducers/data.reducer';

export class Confirmable {
  value = '';
  value_confirmed = false;

  new_value = '';
  confirmation_id = '';
  confirmation_code = '';

  empty_placeholder = '?';

  check_mode = false; // режим проверки
  code_sent = false; // доставлен ли код
  code_checking = false; // выполянется процесс проверки
  code_checked = false; // был ли код проверен
  code_correct = false; // проверка успешная
  get_new_code = false; // можно ли запросить новый код

  get placeholder(): string {
    return this.value.length ? this.value : this.empty_placeholder;
  }

  get is_changed(): boolean {
    return this.new_value.length > 0;
  }
}

export interface IContactsForm {
  email: string;
  email_confirmed: boolean;
  mobile_phone: string;
  mobile_phone_confirmed: boolean;
}

export const isContactsCompleted = (value: IContactsForm): boolean =>
  value.email && value.email_confirmed && value.mobile_phone && value.mobile_phone_confirmed;

export type ConfirmModeType = 'email' | 'mobile_phone';

@Component({
  selector: 'app-contacts-form-page',
  templateUrl: './contacts-form-page.component.html',
  styleUrls: ['./contacts-form-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactsFormPageComponent
  extends ChangableComponent
  implements OnInit, AfterViewInit, CanComponentDeactivate
{
  @Output() changeStep = new EventEmitter<boolean>();

  public mobile_phone = new Confirmable();
  public email = new Confirmable();
  public form: UntypedFormGroup;
  public value: IContactsForm;
  public confirmModalOpen = false;
  public passwordFor2FA: string;
  public errorField: string;
  public displayErrors: boolean;
  public me: User;
  public showSendAgainButton: boolean;

  public confirmModalOpenTimer = 60; // Счетчик времени до повторной отправки кода
  private confirmModalOpenIntervalHolder: any; // Переменная для таймер повторной отправки кода
  private confirmModalTimer: any; // Таймер для закрытия модалки при успехе
  private clearCodeSendDataTimer: any; // Таймер для закрытия модалки при успехе

  private validateFormGroup = ProfileFormService.validateFormGroup;
  public isMobileChanged: boolean;
  public isEmailChanged: boolean;

  public otpInputConfig: Config = {
    allowNumbersOnly: true,
    length: 5,
    inputClass: '',
  };
  public confirmMode: ConfirmModeType;
  public saveState: OnUiButtonState = 'default';
  public required = false;
  public isDisabled = false;
  public statusCode: 'valid' | 'invalid';
  public codeLength = 5;
  public emailTakenText: string;
  public showWarningAlert: boolean;
  public warningMessage: string;
  public showMobileLoader: boolean;
  public is2FA: boolean;
  public is2FAModalOpened: boolean;
  public myCountry: string;
  private countries: Country[];
  public currentLang: string;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    protected fb: UntypedFormBuilder,
    protected contextService: ContextService,
    protected configService: ConfigService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected meta: MetaService,
    protected profileService: ProfileService,
    private profileFormService: ProfileFormService,
    public accountService: AccountService,
    public languageService: LanguageService,
    private toastr: ToastrService,
    protected store: Store,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.profileFormService.profileForm$
      .pipe(
        map((x) => x.contacts),
        filter((x) => !!x),
        take(1),
        takeUntil(this.destroyed$),
      )
      .subscribe((res) => (this.value = res));

    this.configService.countries$
      .pipe(
        tap((countries) => {
          this.countries = countries;
          this.contextService.me$
            .pipe(
              tap((me) => {
                this.me = me;
              }),
              takeUntil(this.destroyed$),
            )
            .subscribe();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    // Init form and set form values
    this.form = this.fb.group({
      mobile_phone: ['', [Validators.required, this.conditionalConfirmPhoneValidator.bind(this)]],
      email: ['', [Validators.required, Validators.email, this.conditionalConfirmEmailValidator.bind(this)]],
    });
    this.form.patchValue(this.value);

    this.profileFormService.verificationRequested$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (requested) => {
        this.required = requested;
        this.validateFormGroup(this.form);
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    this.languageService.language$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (currentLang) => {
        this.currentLang = currentLang.code;
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    this.get2FAStatus();
    this.onMobileChanges();
    this.onEmailChanges();
  }

  public get2FAStatus() {
    this.accountService
      .get2FAStatus()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.is2FA = res;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  ngAfterViewInit(): void {
    // Phone and Email are NOT part of the reactive form
    this.initMobilePhoneAndEmail(this.value);
  }

  private conditionalConfirmEmailValidator(control: UntypedFormControl): ValidationErrors | null {
    if (!this.required) {
      return null;
    }
    return !this.value.email_confirmed ? { confirm: { value: true } } : null;
  }

  private conditionalConfirmPhoneValidator(control: UntypedFormControl): ValidationErrors | null {
    if (!this.required) {
      return null;
    }
    return !this.value.mobile_phone_confirmed ? { confirm: { value: true } } : null;
  }

  private onMobileChanges(): void {
    this.form.controls.mobile_phone.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => {
        const oldMobile = this.value.mobile_phone;
        let newMobile = '';
        if (this.form.get('mobile_phone').value) {
          newMobile = String(this.form.get('mobile_phone')?.value['e164Number']);
        }
        if (newMobile.includes('+')) {
          newMobile = newMobile.replace('+', '');
        }
        this.isMobileChanged = oldMobile !== newMobile;
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
    });
  }

  private onEmailChanges(): void {
    this.form.controls.email.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => {
        this.isEmailChanged = this.value.email !== this.form.get('email').value;
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
    });
  }

  protected initMobilePhoneAndEmail(value: IContactsForm) {
    this.mobile_phone.value = value.mobile_phone;
    if (this.me.country || this.me.country_id) {
      this.myCountry = this.me.country
        ? (this.me.country.alpha2Code as CountryISO).toLowerCase()
        : (this.countries.find((country) => country.id === this.me.country_id).alpha2Code as CountryISO).toLowerCase();
    }
    this.mobile_phone.value_confirmed = value.mobile_phone_confirmed;
    if (value.mobile_phone) {
      this.mobile_phone.check_mode = value.email.length > 0 && !value.email_confirmed;
    } else {
      this.mobile_phone.check_mode = true;
    }
    this.mobile_phone.new_value = '';
    this.mobile_phone.empty_placeholder = 'N NNN NNN NNNN';

    this.email.value = value.email;
    this.email.value_confirmed = value.email_confirmed;
    if (value.email) {
      this.email.check_mode = value.email.length > 0 && !value.email_confirmed;
    } else {
      this.email.check_mode = true;
    }
    this.email.new_value = '';
    this.email.empty_placeholder = 'your@email.com';

    if (value.mobile_phone && value.mobile_phone_confirmed) {
      this.form.get('mobile_phone').disable();
    }

    if (value.email && value.email_confirmed) {
      this.form.get('email').disable();
    }

    logger.log(this.form);
  }

  public changeMobilePhone() {
    this.showMobileLoader = true;
    this.mobile_phone.new_value = this.form.get('mobile_phone').value['e164Number'];
    this.profileService
      .changeMobilePhone(this.mobile_phone.new_value)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (result?: IChangeResult) => {
          if (!result) {
            return;
          }
          switch (result.status) {
            case 'code_required':
              this.mobile_phone.code_sent = true;
              this.mobile_phone.confirmation_id = result.id;
              this.codeLength = result.code_length;
              this.mobile_phone.confirmation_code = '';
              this.mobile_phone.code_checked = false;
              this.confirmModalOpen = true;
              this.showMobileLoader = false;
              this.clearInterval(this.confirmModalOpenIntervalHolder);
              this.startTimer();
              break;

            case 'changed': // если смена без подтверждения
              this.value.mobile_phone = this.mobile_phone.new_value;
              this.value.mobile_phone_confirmed = true;
              this.mobile_phone.value = this.mobile_phone.new_value;
              this.mobile_phone.value_confirmed = true;
              this.mobile_phone.new_value = '';
              this.mobile_phone.check_mode = false;
              this.mobile_phone.confirmation_code = '';
              this.mobile_phone.confirmation_id = '';
              this.confirmModalOpen = true;
              this.showMobileLoader = false;
              this.profileFormService.setProfileForm({ contacts: this.value });
              this.isMobileChanged = false;
              break;

            case 'error':
              this.showMobileLoader = false;
              this.showWarningAlert = true;
              this.warningMessage = result.error;
              this.detectChanges();
              break;
          }
        },
        error: (err) => {
          this.showMobileLoader = false;
          this.showWarningAlert = true;
          this.warningMessage = err?.error;
          this.errorNotificationService.captureError(err, 'PROFILE-PROBLEM');
          this.detectChanges();
        },
      });
  }

  public confirmMobilePhone() {
    this.mobile_phone.code_checking = true;
    this.profileService
      .confirmMobilePhone(this.mobile_phone.confirmation_id, this.mobile_phone.confirmation_code)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (result) => {
          logger.log('confirmMobilePhone', result, this.mobile_phone);
          switch (result.status) {
            case 'code_failed': // если код неверный
              this.mobile_phone.code_sent = true;
              this.mobile_phone.code_checked = true;
              this.mobile_phone.code_correct = false;
              this.statusCode = 'invalid';
              this.otpInputConfig.inputClass = 'is-error';
              break;

            case 'changed': // если смена без подтверждения
              this.value.mobile_phone = this.mobile_phone.new_value;
              this.value.mobile_phone_confirmed = true;
              this.mobile_phone.value = this.mobile_phone.new_value;
              this.mobile_phone.value_confirmed = true;
              this.mobile_phone.new_value = '';
              this.mobile_phone.check_mode = false;
              this.mobile_phone.confirmation_code = '';
              this.mobile_phone.confirmation_id = '';
              this.statusCode = 'valid';
              this.otpInputConfig.inputClass = 'is-success';
              this.confirmModalTimer = this.setTimeout(() => {
                this.stopTimer();
              }, 2000);
              this.profileFormService.setProfileForm({ contacts: this.value });
              break;

            case 'error':
              this.toastr.error(result.error, 'Confirm mobile phone');
              break;
          }

          this.clearCodeSendDataTimer = this.setTimeout(() => {
            this.mobile_phone.code_sent = false;
            this.mobile_phone.code_checked = false;
            this.mobile_phone.code_checking = false;
          }, 5000);
          this.detectChanges();
        },
        error: (err) => {
          this.showMobileLoader = false;
          this.showWarningAlert = true;
          this.warningMessage = err?.error;
          this.errorNotificationService.captureError(err, 'PROFILE-PROBLEM');
        },
      });
  }

  public changeEmail() {
    this.emailTakenText = '';
    this.email.new_value = this.form.get('email').value;
    const isChange = !!this.form.get('email').value;
    this.profileService
      .changeEmail(this.email.new_value)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (result) => {
          logger.log('changeEmail', result);
          switch (result.status) {
            case 'code_required': // если смена с кодом подтверждения
              this.confirmModalOpen = true;
              this.email.code_sent = true;
              this.email.confirmation_id = result.id;
              this.codeLength = result.code_length;
              this.email.confirmation_code = '';
              this.email.code_checked = false;
              this.clearInterval(this.confirmModalOpenIntervalHolder);
              this.startTimer();
              break;

            case 'changed': // если смена без подтверждения
              this.value.email = this.email.new_value;
              this.value.email_confirmed = true;
              this.email.value = this.email.new_value;
              this.email.value_confirmed = true;
              this.email.new_value = '';
              this.email.check_mode = false;
              this.email.confirmation_code = '';
              this.email.confirmation_id = '';
              this.profileFormService.setProfileForm({ contacts: this.value });
              this.isEmailChanged = false;
              this.confirmModalOpen = true;
              break;

            case false:
              this.emailTakenText = result.statusText;
              break;

            case 'error':
              this.toastr.error(result.error, isChange ? 'Change email' : 'Confirm email');
              break;
          }
          this.detectChanges();
        },
        error: (err) => {
          this.errorNotificationService.captureError(err, 'PROFILE-PROBLEM');
        },
      });
  }

  public get isProfileComplete(): boolean {
    return true; // !this.profileFormService.isIncompleteProfile();
  }

  public confirmEmail() {
    this.email.code_checking = true;
    this.profileService
      .confirmEmail(this.email.confirmation_id, this.email.confirmation_code)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (result) => {
          logger.log('confirmEmail', result, this.email);
          switch (result.status) {
            case 'code_failed': // если код неверный
              this.email.code_sent = true;
              this.email.code_checked = true;
              this.email.code_correct = false;
              this.statusCode = 'invalid';
              this.otpInputConfig.inputClass = 'is-error';
              break;

            case 'changed': // если код верный
              this.value.email = this.email.new_value;
              this.value.email_confirmed = true;
              this.email.value = this.email.new_value;
              this.email.new_value = '';
              this.email.value_confirmed = true;
              this.email.check_mode = false;
              this.email.confirmation_code = '';
              this.email.confirmation_id = '';
              this.statusCode = 'valid';
              this.otpInputConfig.inputClass = 'is-success';
              this.confirmModalTimer = this.setTimeout(() => {
                this.stopTimer();
              }, 2000);
              this.value.email = this.form.get('email').value;
              this.profileFormService.setProfileForm({ contacts: this.value });
              break;

            case 'error':
              this.toastr.error(result.error, 'Confirm email');
              break;
          }

          this.clearCodeSendDataTimer = this.setTimeout(() => {
            this.email.code_sent = false;
            this.email.code_checked = false;
            this.email.code_checking = false;
          }, 5000);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });
  }

  protected setupMeta() {
    this.meta.setTitle(['profile-form.security.meta-title']);
  }

  public navigateBack(): void {
    this.router.navigate(['/profile/about']).then(() => {});
  }

  public navigateNextStep(): void {
    this.changeStep.emit(true);
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return true;
  }

  public onEnableMobilePhone() {
    this.form.get('mobile_phone').enable();
  }

  public onDisableMobilePhone() {
    this.form.get('mobile_phone').disable();
  }

  public onEnableEmail() {
    this.form.get('email').enable();
  }

  public onDisableEmail() {
    this.form.get('email').disable();
  }

  public onToggleConfirmModal(mode?: ConfirmModeType) {
    this.showSendAgainButton = false;
    this.confirmModalOpenTimer = 60;
    this.confirmMode = null;
    this.confirmMode = mode;
    this.statusCode = null;
    this.otpInputConfig.inputClass = '';

    // Сброс таймеров для очистки code-send data
    this.clearTimeout(this.clearCodeSendDataTimer);

    switch (mode) {
      case 'email':
        this.changeEmail();
        break;
      case 'mobile_phone':
        this.changeMobilePhone();
        break;
    }
  }

  stopTimer(): void {
    this.confirmModalOpen = false;
    this.clearInterval(this.confirmModalOpenIntervalHolder);
    this.clearTimeout(this.confirmModalTimer);
    this.confirmModalOpenTimer = 60;
    if (this.value.email_confirmed) {
      this.onDisableEmail();
    }

    if (this.value.mobile_phone_confirmed) {
      this.onDisableMobilePhone();
    }
    this.isMobileChanged = false;
    this.isEmailChanged = false;
    this.detectChanges();
  }

  public onOtpChange(event: string) {
    if (event.length === this.codeLength) {
      switch (this.confirmMode) {
        case 'email':
          this.email.confirmation_code = event;
          this.confirmEmail();
          break;
        case 'mobile_phone':
          this.mobile_phone.confirmation_code = event;
          this.confirmMobilePhone();
          break;
      }
    } else if (event.length === 0) {
      this.statusCode = null;
      this.otpInputConfig.inputClass = '';
    }
    this.detectChanges();
  }

  private startTimer(): void {
    // Отсчет времени до повторной отправки кода
    this.confirmModalOpenIntervalHolder = this.setInterval(() => {
      this.confirmModalOpenTimer = this.confirmModalOpenTimer - 1;
      if (this.confirmModalOpenTimer <= 0) {
        this.showSendAgainButton = true;
        this.clearInterval(this.confirmModalOpenIntervalHolder);
        this.clearTimeout(this.confirmModalTimer);
      }
      this.detectChanges();
    }, 1000);
  }

  public get getMobilePhoneText() {
    const value = this.form.get('mobile_phone').value['e164Number'];
    if (value[0] === '+') {
      return value.substring(1, value.length);
    }
    return value;
  }

  public isInvalid(control: string): boolean {
    return this.displayErrors && (!this.passwordFor2FA || this.errorField === control);
  }

  public toggle2FAModal() {
    if (this.is2FA) {
      this.turnOff2FA();
    } else {
      this.is2FAModalOpened = !this.is2FAModalOpened;
    }
  }

  public turnOff2FA() {
    this.displayErrors = false;
    if (!this.passwordFor2FA) {
      this.displayErrors = true;
      return;
    }

    const body = {
      password: this.passwordFor2FA,
    };

    this.accountService
      .turnOff2FACode(body)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.get2FAStatus();
          this.passwordFor2FA = '';
          this.detectChanges();
        },
        error: (err) => {
          this.displayErrors = true;
          this.errorField = err.error?.invalid_field;
          this.errorNotificationService.captureError(err, 'PROFILE-PROBLEM');
        },
      });
  }

  public on2FASaved(e: boolean) {
    if (e) {
      this.get2FAStatus();
      this.toggle2FAModal();
    }
  }
}
